const ServiceData =[
        {
          id:"ss1",
          icon:"fas fa-layer-group",
          title: "Market Forecast",
          text: "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
          iconBackground:"#6001d3"
        },
        {
          id:"ss2",
          icon:"fas fa-bell",
          title: "Strategic planning",
          text:
            "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
            iconBackground:"#fd346e"
        },
        {
          id:"ss3",
          icon:"fas fa-envelope",
          title:"Budget Accounting",
          text:
            "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
            iconBackground:"#1de2cf"
        },
        {
          id:"ss4",
          icon:"fas fa-chart-pie",
          title: "Target Marketing",
          text:
            "There are many variations passages of Lorem lpsum majority, some words don’t look believable if you are going to use.",
            iconBackground:"#ffd700"
        }
    ]
  export default ServiceData;